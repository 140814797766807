import React from "react";
import {useRoutes, useRedirect} from "hookrouter";
import routes from "./Router";
import NotFound from "./generic/NotFound";


function App() {

  useRedirect("/index2.html", "/");

  const routeResult = useRoutes(routes);

  return (
    routeResult || <NotFound />
  ); 
};

export default App;