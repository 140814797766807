import React from "react";
import Header from "./generic/Header";
import Nav from "./generic/Nav";
import Footer from "./generic/Footer";

function Projects () {
    return (
        <section id="projects" className="projects">
            <Header />
            <Nav />
            <div className="projects__container">
                
                <div className="projects__1st-row">

                    <div className="row">
                        <div className="col-1-of-2">
                            <div className="projects__text-box">
                                <h1 className="projects__heading-primary">London Eye NYE</h1>

                                <p>Backroom was asked to supply power in each of the pods of the London Eye for New Years Eve celebrations.</p>
                                <br></br>
                                <p>Each of the pods on the eye have limited power available for events. We set up a UPS system so the live stream event went seamless.</p>
                            </div>

                        </div>

                        <div className="col-1-of-2">

                            <div className="projects__image-box">
                                <img src="../images/London_Eye_Distros.png" alt="London Eye New Years Eve Power Distribution" className="projects__image" />
        
                                <img src="../images/London_Eye_New_Years_Eve.png" alt="London Eye New Years Eve" className="projects__image" />
                            </div>

                        </div>

                    </div>

                    <div className="row projects__2nd-row">
                        <div className="col-1-of-2">
                            <div className="projects__text-box">
                                <h1 className="projects__heading-primary">Game of Thrones</h1>

                                <p>One of the most successful TV shows of all time required three 90kVa Iso Transformers to step down from 415v to 208v.</p>
                                <br></br>
                                <p>These were being used for a “Fly Wire Cam” system that was essential to filming major battle scenes. The transformers were on hire for the duration of filming.</p>
                            </div>

                        </div>

                        <div className="col-1-of-2">

                            <div className="projects__image-box">
                                <img src="../images/Game of Thrones.png" alt="Game of Thrones" className="projects__image" />

                                {/* <img src="../images/Roger_Waters_The_Wall.png" alt="Roger Waters The Wall" className="projects__image" /> */}
                            </div>

                        </div>

                    </div>

                </div>

            </div>

            <div className="projects__container-2">

            <div className="projects__1st-row">
                <div className="row">
                        <div className="col-1-of-2">
                            <div className="projects__text-box">
                                <h1 className="projects__heading-primary">The Who</h1>

                                <p>The Who are a long term client of ours. Over the years we have supplied Veriacs allowing the backline techs to dial in an exact voltage in a range from 80v - 280v. We also supply the tour with Step-Up Transformers to ensure while touring America (110v) the band have European (240v) voltage onstage.</p>
                                <br></br>
                                <p>For a show at Wembley Stadium we supplied Frequency cycle convertors to allow the Hammond to run at the correct frequency.</p>
                            </div>

                        </div>

                        <div className="col-1-of-2">

                            <div className="projects__image-box">
                                <img src="../images/The Who Wembley.png" alt="The Who Wembley" className="projects__image-large" />

                                {/* <img src="../images/Roger_Waters_The_Wall.png" alt="Roger Waters The Wall" className="projects__image" /> */}
                            </div>

                        </div>

                    </div>

                <div className="row projects__2nd-row">

                        <div className="col-1-of-2">
                            <div className="projects__text-box">
                                <h1 className="projects__heading-primary">Roger Waters</h1>

                                {/* <p>The Who are a long term client of ours. Over the years we have supplied Veriacs allowing the backline techs to dial in an exact voltage in a range from 80v - 280v. We also supply the tour with Step-Up Transformers to ensure while touring America (110v) the band have European (240v) voltage onstage.</p>
                                <br></br>
                                <p>For a show at Wembley Stadium we supplied Frequency cycle convertors to allow the Hammond to run at the correct frequency.</p> */}
                            </div>

                        </div>

                        <div className="col-1-of-2">

                            <div className="projects__image-box">
                                <img src="../images/Roger_Waters_PDU.png" alt="Roger Waters PDU" className="projects__image" />

                                <img src="../images/Roger_Waters_The_Wall.png" alt="Roger Waters The Wall" className="projects__image" />
                            </div>

                        </div>

                    </div>
            </div>
                
            </div>

            <div className="projects__container-3">
                <div className="projects__1st-row">
                        

                    <div className="row">
                        <div className="col-1-of-2">
                            <div className="projects__text-box">
                                <h1 className="projects__heading-primary">Henley Festival with RG Jones</h1>

                                <p>RG Jones is a long term client whom we work with each year on the Henley festival. The local supplied voltage is consistently high, metering between phases 440v-450v.</p>
                                <br></br>
                                <p>We supply a 90kVa Iso Transformer to lower the voltage to the standard 415v to ensure a smooth running of the equipment and event. </p>
                            </div>

                        </div>

                        <div className="col-1-of-2">

                            <div className="projects__image-box">
                                <img src="../images/Henley Festival.png" alt="Henley Festival" className="projects__image-large" />

                                {/* <img src="../images/Roger_Waters_The_Wall.png" alt="Roger Waters The Wall" className="projects__image" /> */}
                            </div>

                        </div>

                    </div>

                    <div className="row projects__2nd-row">
                        <div className="col-1-of-2">
                            <div className="projects__text-box">
                                <h1 className="projects__heading-primary">Tutankhamun Exhibition with Full Production Limited</h1>

                                <p>Backroom was approached by Full Production Limited to provide multiple Iso Transformers to step down from 415v to 208v</p>
                                <br></br>
                                <p>The American production which runs the Tutankhamun exhibition wanted to use their American security camera system that ran at 110v. They needed a Continuous Transformer Coil that could run for months under load without issues arising. Upon receiving the specs, we decided to build 5 custom transformers from scratch to ensure that the client had new units.</p>
                            </div>

                        </div>

                        <div className="col-1-of-2">

                            <div className="projects__image-box">
                                <img src="../images/Tutankhamun.png" alt="Tutankhamun" className="projects__image-large" />

                                {/* <img src="../images/Roger_Waters_The_Wall.png" alt="Roger Waters The Wall" className="projects__image" /> */}
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            
            <Footer />
        </section>
    )
};

export default Projects;