import React from 'react';
import { navigate } from 'hookrouter';
import SocialShare from "./SocialShares";

function Footer() {

	const shareURL = "https://www.backroom.co.uk";


	return (
		<footer className="footer">
			<div className="footer__logo-box">
				<img src="../images/Backroom International Hire Solutions.png" alt="Backroom International Hire Solutions Logo" className="footer__logo" />
			</div>

			<div className="">
				<div className="">
					<div className="footer__navigation">
						<ul className="footer__list">
							<li className="footer__item">
								<a href="/" className="footer__link">
									Home
								</a>
							</li>
							<li className="footer__item">
								<a href="/projects" className="footer__link">
									Projects
								</a>
							</li>
							<li className="footer__item">
								<a href="/retro" className="footer__link">
									Retro
								</a>
							</li>
							<li className="footer__item">
								<a href="/tscs" className="footer__link">
									Ts & Cs
								</a>
							</li>
							<li className="footer__item">
								<a href="/contact" className="footer__link">
									Contact
								</a>
							</li>
						</ul>
					</div>

					<SocialShare shareURL={shareURL}
                        title="Backroom International Hire Solutions"
                        summary="Location Equipment Hire"
                        hashtag="#backroom"
                    />

					<div className="footer__copyright">
						<h6 className="footer__copyright-text">Copyright Backroom Limited &#169;</h6>

						<a href="https://www.adampower.io" target="_blank" className="footer__apwebdev">Site by Adam Power Web Development</a>
					</div>
				</div>
				
			</div>
		</footer>
	);
}

export default Footer;
