import React, { useState } from "react";
import { navigate } from "hookrouter";
import Nav from "./Nav";
import Header from "./Header";
import Footer from "./Footer";
require("dotenv").config()

function NotFound () {

    
    const navHome = () => {
        navigate("/");
    };
    


    

    return (
        <section id="contact" className="contact">
            <Nav />
            <Header />
            <div className="notfound__container">
                
                <h1 className="notfound__heading-primary">Page Not Found</h1>
                
                <form onSubmit={navHome}>
                    <button className="notfound__button" type ="submit">Home</button>
                </form>
                
            </div>

            <Footer />

            
        </section>
    )
};

export default NotFound;