import React, { useState } from "react";
import { navigate } from "hookrouter";
import Nav from "./generic/Nav";
import Header from "./generic/Header";
import Footer from "./generic/Footer";
require("dotenv").config()

function Contact () {

    

    


    

    return (
        <section id="contact" className="contact">
            <Nav />
            <Header />
            <div className="contact__container">
                
                <h1 className="contact__heading-primary">Say Hello</h1>
                <a className="contact__email" href="mailto:backroomltd@gmail.com">backroomltd@gmail.com</a>
                <h2 className="contact__mobile">+44 07956 549665</h2>

                
            </div>

            <Footer />

            
        </section>
    )
};

export default Contact;