import React, { useEffect, useState } from "react";
import {navigate} from "hookrouter";


function HeaderMain() {

  

  return (
    <header>
      <div className="header__box">
			  	<img src="../images/Backroom International Hire Solutions.png" alt="Backroom International Hire Solutions Logo" className="header__box__logo" />
      </div>

    </header>
    
  ); 
};

export default HeaderMain;

