import React from "react";
import Nav from "./generic/Nav";

function Retro () {
    return (
        <section id="retro" className="retro">
            <Nav />
            <div className="retro">
                <img src="../images/Backroom_Old_Site.png" alt="Backroom Limited Old Site" className="retro" />
            </div>
        </section>
    )
};

export default Retro;