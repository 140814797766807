import React from "react";
import Nav from "./generic/Nav";
import Header from "./generic/Header";
import IndexMain from "./IndexMain";
import Footer from "./generic/Footer";



function Index() {

  return (
    <body>
      <Nav />
      <Header />
      <IndexMain />
      <Footer />
    </body>
  ); 
};

export default Index;

