import React, { useEffect } from 'react';
import Nav from './generic/Nav';
import Header from './generic/Header';
import Footer from './generic/Footer';

function TSCS() {
	useEffect(() => {
		const thanks = true;
	});

	return (
		<div id="tscs" className="tscs">
			<Nav />
			<Header />
			<div className="tscs__container" />

			<div className="tscs__container-2" />

			<div className="tscs__container-3" />

			<div className="tscs__container-4" />

			<div className="tscs__container-5" />

			<div className="tscs__container-6" />

			<div className="tscs__container-6" />

			<div className="tscs__terms-box">
				<h1 className="tscs__heading-primary">T's & C's</h1>

				<img className="tscs__payment-method" src="../images/payment_methods/Visa-Logo.png" alt="Visa" />
				<img
					className="tscs__payment-method"
					src="../images/payment_methods/Mastercard_Logo.png"
					alt="Mastercard"
				/>
				<img className="tscs__payment-method" src="../images/payment_methods/Maestro_Logo.png" alt="Maestro" />
				<img
					className="tscs__payment-method"
					src="../images/payment_methods/AMEX_Logo.png"
					alt="American Express"
				/>

				<div className="tscs__text-box">
					<h5 className="tscs__section-heading">1. Table of Definitions used in these terms</h5>
					<p className="tscs__term-number">1.1.1</p>
					<p className="tscs__term-definition">
						CONTRACT means a CONTRACT for the hire or SALE of EQUIPMENT concluded by the acceptance by the
						OWNER of an ORDER as defined under Condition 2 and 2.1 below.
					</p>

					<p className="tscs__term-number">1.1.2</p>
					<p className="tscs__term-definition">
						EQUIPMENT means the EQUIPMENT described in any quotation/delivery note or any individual item
						thereof.
					</p>

					<p className="tscs__term-number">1.1.3</p>
					<p className="tscs__term-definition">
						HIRE CHARGE means the weekly charge payable by the HIRER to the OWNER for the hire of the
						EQUIPMENT, which is set out in any quotation provided by the OWNER or posted on any offer of
						EQUIPMENT hire whether verbal or written.
					</p>

					<p className="tscs__term-number">1.1.4</p>
					<p className="tscs__term-definition">
						HIRE PERIOD means a period starting on the date that the EQUIPMENT is dispatched to or collected
						by the HIRER and ending on the date on which the EQUIPMENT is returned to the OWNER. In general
						the minimum HIRE PERIOD period is 1 week. At all times the minimum HIRE PERIOD is decided by the
						OWNER. Certain items of EQUIPMENT have different minimum HIRE PERIODS.
					</p>

					<p className="tscs__term-number">1.1.5</p>
					<p className="tscs__term-definition">
						HIRER means the HIRER of, or the purchaser of the EQUIPMENT from the OWNER.
					</p>

					<p className="tscs__term-number">1.1.6</p>
					<p className="tscs__term-definition">
						OWNER means the company BACKROOM LTD or any other Backroom Group Company.
					</p>

					<p className="tscs__term-number">1.1.7</p>
					<p className="tscs__term-definition">
						USER means in all cases the personnel connecting and operating the EQUIPMENT. Normally but not
						necessarily an employee or sub contractor of the HIRER
					</p>

					<p className="tscs__term-number">1.1.8</p>
					<p className="tscs__term-definition">
						SALE means title of the EQUIPMENT transfers to the party who has paid in full the agreed sum to
						purchase same.
					</p>

					<h5 className="tscs__section-heading">2. Order for Hire</h5>
					<p className="tscs__term-definition">
						The OWNER shall only accept an order for hire of the EQUIPMENT provided that it is in writing.
						Unless otherwise agreed, the written acceptance of the HIRER'S order by the OWNER shall
						constitute the CONTRACT , which shall be subject to these Conditions. Any terms or conditions in
						the HIRER'S order or other documentation of whatsoever kind which are inconsistent with these
						Conditions, shall have no effect. The OWNER may vary the conditions of any hire CONTRACT at any
						time as long as notified in writing to any HIRER.
					</p>

					<h5 className="tscs__section-heading">2.1 Orders for Sale</h5>
					<p className="tscs__term-definition">
						The OWNER shall accept orders for sale of the EQUIPMENT as on the terms for HIRE. The goods
						title will only pass to the purchaser when all sums due under the agreement have been duly paid
						and cleared the OWNER'S bank accounts.
					</p>

					<h5 className="tscs__section-heading">2.2 Cancellation / Postponement of order</h5>
					<p className="tscs__term-definition">
						Should the HIRER cancel the ORDER for HIRE 1wks full charges will be payable.
					</p>
					<p className="tscs__term-definition">
						Postponement due to HIRERS causes will be deemed as cancellation if no reasonable alternative
						date is given for the hire.{' '}
					</p>
					<p className="tscs__term-definition">
						In the case of special projects, cancellation will be charged at full project rate, and
						postponement will incur additional Hire charges. In the case of special projects additional
						charges may be due if the equipment has to be re configured in order to meet the project
						requirements. Depending on the time schedule for the Hire and equipment demands Cancellation and
						Postponement charges may be varied or waived. The HIRER agrees to pay said charges as demanded.
					</p>

					<h5 className="tscs__section-heading">3. Hire of Equipment</h5>

					<p className="tscs__term-number">3.1</p>
					<p className="tscs__term-definition">
						The OWNER hereby agrees to let for hire to the HIRER the EQUIPMENT for the HIRE PERIOD at the
						agreed HIRE CHARGE.
					</p>

					<p className="tscs__term-number">3.2</p>
					<p className="tscs__term-definition">
						The OWNER reserves the right to supply suitable alternatives of a similar design and electrical
						specification to the EQUIPMENT.
					</p>

					<p className="tscs__term-number">3.3</p>
					<p className="tscs__term-definition">
						All descriptions and specifications including battery autonomy, drawings and particulars of
						weights and dimensions issued by the OWNER are approximate only, and are intended only to
						present a general idea of the goods to which they refer and shall not form part of the CONTRACT.
						Due to continuing development, the OWNER reserves the right to change specifications without
						notice.
					</p>

					<h5 className="tscs__section-heading">4. Payment of Fees and Charges</h5>

					<p className="tscs__term-number">4.1</p>
					<p className="tscs__term-definition">
						The HIRER shall pay to the OWNER either in advance or on a weekly basis a sum calculated by
						multiplying the appropriate HIRE CHARGE for the EQUIPMENT by the HIRE PERIOD for such items of
						EQUIPMENT. All HIRE CHARGES are strictly nett and are due and payable on invoice submitted
						within 30 days. HIRE CHARGES run from day of dispatch until day of return and are based on a
						period of one week or part thereof irrespective of whether the goods are in use or not. A period
						of two days grace is normally given on collection and deliveries (ie, collection Friday: return
						Monday).
					</p>

					<p className="tscs__term-number">4.2</p>
					<p className="tscs__term-definition">
						The HIRER shall pay to The OWNER any packing and delivery charges where appropriate.
					</p>

					<p className="tscs__term-number">4.3</p>
					<p className="tscs__term-definition">
						All sums due from the HIRER to the OWNER hereunder shall be increased to include VAT at the
						prevailing rate at time of invoice.
					</p>

					<p className="tscs__term-number">4.4</p>
					<p className="tscs__term-definition">
						The HIRER shall pay the OWNER invoices hereunder within 30 days of the date of invoice. Time
						shall be of the essence in respect of the payment of all sums due hereunder.
					</p>

					<p className="tscs__term-number">4.5</p>
					<p className="tscs__term-definition">
						The OWNER reserve the right to charge the HIRER interest and charges if indicated, as per the
						provisions of The Late Payment of Commercial Debts (Interest) Act 1998. This allows for an
						interest rate of 8% above the mean base rate for the time being of the London Clearing Banks on
						all invoices, which are not paid in accordance with Condition 4,4, such interest being
						calculated from a date 30 days after the date of invoice until actual payment compounded
						quarterly and to be payable as well after as before any judgment obtained in respect thereof.
					</p>

					<h5 className="tscs__section-heading">5. Delivery</h5>

					<p className="tscs__term-number">5.1</p>
					<p className="tscs__term-definition">
						Any dates and times quoted for delivery of the EQUIPMENT are approximate only and The OWNER
						shall not be liable for any delay in delivery of the EQUIPMENT howsoever caused.
					</p>

					<p className="tscs__term-number">5.2</p>
					<p className="tscs__term-definition">
						The HIRER shall conduct a reasonable inspection of the EQUIPMENT upon receipt. If such
						inspection reveals that the EQUIPMENT is damaged, or that some of the EQUIPMENT is damaged, or
						that some of the EQUIPMENT has been lost, the HIRER shall notify the OWNER and the carrier in
						writing within 3 days of the date of receipt. Damaged EQUIPMENT may be retained by the HIRER for
						inspection by the carrier provided that it shall be returned to the OWNER on demand. If the
						HIRER fails to notify the OWNER and the carrier in accordance with this Condition, or to return
						the EQUIPMENT on demand the HIRER shall pay to the OWNER the full cost of repair or replacement
						of the EQUIPMENT or full HIRE CHARGE until returned.
					</p>

					<p className="tscs__term-number">5.3</p>
					<p className="tscs__term-definition">
						For HIRERS collecting and returning EQUIPMENT, the OWNER'S normal working hours are Monday to
						Friday 8am - 6pm. (Emergency Service is available outside these hours).
					</p>

					<h5 className="tscs__section-heading">6. Use of Equipment</h5>

					<p className="tscs__term-number">6.1</p>
					<p className="tscs__term-definition">
						The HIRER shall ensure that the EQUIPMENT is installed and used by competent or qualified
						personnel in a manner which complies with any applicable statute, regulation or order from time
						to time in force affecting the EQUIPMENT including but not limited to the Electricity at Work
						Regulations 1989 (BS7671) and any statutory amendment or replacement of it the latest being IE
						17th EDITION 2008. BS7909 shall apply to all temporary installations
					</p>

					<p className="tscs__term-number">6.1.1</p>
					<p className="tscs__term-definition">
						All the EQUIPMENT is designated to be connected to or form some part of an electrical system
						operating on either DC or AC. The EQUIPMENT operating voltage is dependent on the type of
						EQUIPMENT supplied but can be as much as 3-Phase 600 VOLTS AC (350V/ Phase). Some EQUIPMENT
						offered for hire will remain live even without input power present.
					</p>

					<p className="tscs__term-number">6.1.2</p>
					<p className="tscs__term-definition">
						Electricity is dangerous. Lack of knowledge and or appropriate experience and precautions with
						the considerable risks involved can be FATAL.
					</p>

					<p className="tscs__term-number">6.1.3</p>
					<p className="tscs__term-definition">
						The HIRER must ensure that the USER is sufficiently trained and made aware of the possible risks
						and or dangers involved in using the EQUIPMENT.
					</p>

					<p className="tscs__term-number">6.1.4</p>
					<p className="tscs__term-definition">
						The HIRER agrees that any and all instructions supplied by the OWNER for the safe use of the
						EQUIPMENT will be presented to the USER of the EQUIPMENT and that the USER is instructed to
						contact the OWNER with any questions or concerns BEFORE connecting or attempting to use the
						EQUIPMENT.
					</p>

					<p className="tscs__term-definition">HIRERS, CUSTOMERS and USERS Obligations regarding use.</p>

					<p className="tscs__term-definition">It is the customers duty to ensure the following...</p>

					<p className="tscs__term-definition">1) Load is appropriate for the equipment.</p>

					<p className="tscs__term-definition">
						2) Equipment is not damaged due to excessive harmonics or other over loading.
					</p>

					<p className="tscs__term-definition">
						3) The equipment is used correctly at all times, by competent persons only.
					</p>

					<p className="tscs__term-definition">4) is regularly monitored and checked out before each use.</p>

					<p className="tscs__term-number">6.2</p>
					<p className="tscs__term-definition">
						The HIRER shall at its expense keep the EQUIPMENT in good repair, condition and working order,
						fair wear and tear excepted. Without prejudice to the generality of the foregoing, the HIRER
						shall on a very regular basis and certainly every week:
					</p>

					<p className="tscs__term-number">6.2.1</p>
					<p className="tscs__term-definition">
						Inspect, monitor system status, test and clean the EQUIPMENT.
					</p>

					<p className="tscs__term-number">6.2.2</p>
					<p className="tscs__term-definition">Check and maintain all wiring to and from the EQUIPMENT.</p>

					<p className="tscs__term-number">6.3</p>
					<p className="tscs__term-definition">
						The HIRER nor the USER shall not without the prior written consent of the OWNER make any
						modification or alteration to the EQUIPMENT, or take the EQUIPMENT outside mainland Great
						Britain.
					</p>

					<p className="tscs__term-number">6.4</p>
					<p className="tscs__term-definition">
						The HIRER shall allow the OWNER to inspect the EQUIPMENT upon request during the OWNER'S normal
						working hours.
					</p>

					<p className="tscs__term-number">6.5</p>
					<p className="tscs__term-definition">
						The HIRER shall report any and all faults to the OWNER. The replacement cost of the EQUIPMENT
						will be charged to the HIRER if it is not returned.
					</p>

					<p className="tscs__term-number">6.6</p>
					<p className="tscs__term-definition">
						The HIRER must not undertake Electrical PAT tests on any EQUIPMENT supplied by the OWNER unless
						agreed in writing. PAT testing will damage certain types of EQUIPMENT.
					</p>

					<h5 className="tscs__section-heading">7. Ownership</h5>
					<p className="tscs__term-definition">
						Unless otherwise stated in writing to the HIRER by the OWNER, The EQUIPMENT shall at all times
						remain the property of The OWNER and the HIRER shall have no rights to the EQUIPMENT other than
						as HIRER and the HIRER shall not do or permit or cause to be done any matter or thing whereby
						the rights of The OWNER in respect of the EQUIPMENT are or may be prejudicially affected. Where
						equipment is sub hired on behalf on HIRER, this will be made clear. All sub hired equipment
						delivery notes will be made out to HIRER, Insurance will be at all times HIRERS obligation.
					</p>

					<h5 className="tscs__section-heading">8. Loss, Damage and Insurance</h5>

					<p className="tscs__term-number">8.1</p>
					<p className="tscs__term-definition">
						In the event of any loss or damage to the EQUIPMENT the HIRER shall pay for its replacement, or
						the cost of restoring it to good working order, or allow the OWNER or its agent to carry out
						such work at the HIRER'S expense.
					</p>

					<p className="tscs__term-number">8.2</p>
					<p className="tscs__term-definition">
						The HIRER shall during the HIRE PERIOD (without prejudice to the liability of the HIRER to the
						OWNER pursuant to Condition 8.1) keep the EQUIPMENT insured for its full replacement value with
						a reputable insurance company against loss or damage from all risks (including third party
						risks). The HIRER shall notify its insurers that the EQUIPMENT is on hire from the OWNER and
						request the insurers to endorse a note of such interest on the policy, naming the OWNER as loss
						payee. The HIRER shall on demand show to the OWNER the policy, the premium receipts and
						insurance certificate and shall not use or allow the EQUIPMENT to be used for any purpose not
						permitted by the terms and conditions of the said policy or do or allow to be done any act or
						thing whereby the insurance may be invalidated.
					</p>

					<p className="tscs__term-number">8.3</p>
					<p className="tscs__term-definition">
						Where any event or accident shall occur which is a risk covered by the HIRER'S insurance
						hereunder the HIRER shall immediately notify the OWNER thereof. The HIRER shall hold any monies
						received by the same as the OWNER directs.
					</p>

					<h5 className="tscs__section-heading">9. Hirer's Indemnities</h5>

					<p className="tscs__term-number">9.1</p>
					<p className="tscs__term-definition">
						The HIRER shall be solely responsible for and hold the OWNER fully indemnified against all
						claims, demands, liabilities, losses, damages, proceedings, costs and expenses which may be
						brought against or incurred by the OWNER as a result of any accident or loss involving the
						EQUIPMENT.
					</p>

					<p className="tscs__term-number">9.2</p>
					<p className="tscs__term-definition">
						The HIRER shall be solely responsible for and hold the OWNER fully indemnified against all
						claims, demands, liabilities, losses, damages, proceedings, costs and expenses which may be
						brought against or incurred by the OWNER as a result of any breach or default on the part of the
						HIRER or the USER in the discharge of their obligations under any CONTRACT.
					</p>

					<h5 className="tscs__section-heading">10. Limitations of liability</h5>

					<p className="tscs__term-number">10.1</p>
					<p className="tscs__term-definition">
						The OWNER liability for direct physical damage to tangible property of the HIRER caused by the
						negligence of the OWNER, its employees agents or subcontracts, or by breach of any CONTRACT,
						shall not exceed £500,000 subject to the exclusions set out in Condition 10.2.
					</p>

					<p className="tscs__term-number">10.2</p>
					<p className="tscs__term-definition">
						The OWNER shall not be liable for the following loss or damage howsoever caused and even damages
						howsoever caused and even if foreseeable by or in contemplation of the OWNER.
					</p>

					<p className="tscs__term-number">10.2.1</p>
					<p className="tscs__term-definition">
						Loss of profits, business, revenue, goodwill or anticipated savings whether sustained by the
						HIRER or any other person; or;
					</p>

					<p className="tscs__term-number">10.2.2</p>
					<p className="tscs__term-definition">
						Special, indirect or consequential loss other than direct physical damage to tangible property
						of the HIRER or any other person or;
					</p>

					<p className="tscs__term-number">10.2.3</p>
					<p className="tscs__term-definition">
						Any loss arising from any claim made against the OWNER by any other person.
					</p>

					<h5 className="tscs__section-heading">11. Termination</h5>

					<p className="tscs__term-number">11.1</p>
					<p className="tscs__term-definition">
						The OWNER shall have the right to terminate any CONTRACT forthwith by giving notice in writing
						if the HIRER:
					</p>

					<p className="tscs__term-number">11.1.1</p>
					<p className="tscs__term-definition">
						Fails to make payment of any sum in accordance due with Condition 4 or;
					</p>

					<p className="tscs__term-number">11.1.2</p>
					<p className="tscs__term-definition">
						Shall commit any other material breach of its obligations hereunder and shall not within 14 days
						of notice of such breach remedy the same or;
					</p>

					<p className="tscs__term-number">11.1.3</p>
					<p className="tscs__term-definition">
						Shall enter into liquidation whether compulsorily or voluntarily otherwise than for the purpose
						of amalgamation or reconstruction without insolvency or shall compound or make any arrangement
						with its creditors or shall be the subject of an application for an administration order or
						shall be subject of any proposal under Part 1 of the Insolvency Act 1986 for a composition in
						satisfaction of its debts.
					</p>

					<h5 className="tscs__section-heading">12. Consequences of Termination</h5>

					<p className="tscs__term-number">12.1</p>
					<p className="tscs__term-definition">
						Any termination of a CONTRACT (howsoever occasioned) shall not affect any accrued rights or
						liabilities of either party.
					</p>

					<p className="tscs__term-number">12.2</p>
					<p className="tscs__term-definition">
						On termination of a CONTRACT (howsoever occasioned) the HIRER shall no longer be in possession
						of the EQUIPMENT with the OWNER'S consent and shall unless otherwise agreed with the OWNER
						forthwith return the EQUIPMENT to the OWNER in good working order.
					</p>

					<h5 className="tscs__section-heading">13. Force Majure</h5>
					<p className="tscs__term-definition">
						Although the OWNER shall use all reasonable endeavours to discharge its obligation under a
						CONTRACT in a prompt and efficient manner, it does not and will not accept any responsibility
						for any failure or delay caused by circumstances beyond its control.
					</p>

					<h5 className="tscs__section-heading">14. General</h5>

					<p className="tscs__term-number">14.1</p>
					<p className="tscs__term-definition">
						No neglect, delay or indulgence by the OWNER in enforcing a CONTRACT shall prejudice the rights
						of the OWNER or be construed as a waiver.
					</p>

					<p className="tscs__term-number">14.2</p>
					<p className="tscs__term-definition">
						The HIRER hereby waives all and any future claims and rights of set off against any sums due to
						the OWNER hereunder regardless of any equity, set off or counter-claim on the part of the HIRER
						against the OWNER.
					</p>

					<p className="tscs__term-number">14.3</p>
					<p className="tscs__term-definition">
						Any notice hereunder shall be in writing and may be served by sending it by pre paid first class
						letter post or delivery if (in the case of a limited company) to the address stated herein, and
						in any other case, to the last known address of the addressee. In proving service of any notice
						it shall be sufficient to prove that the envelope containing the notice was properly addressed,
						stamped and posted. Service shall be deemed to be effective at noon of the second business day
						following the day of posting and any notice delivered to an address by hand shall be deemed to
						be effective from the date of such delivery.
					</p>

					<p className="tscs__term-number">14.4</p>
					<p className="tscs__term-definition">
						The HIRER shall not assign or otherwise transfer all or any part of a CONTRACT.
					</p>

					<p className="tscs__term-number">14.5</p>
					<p className="tscs__term-definition">
						The formation, construction and performance of all CONTRACTS shall be governed in all respect by
						English Law.
					</p>

					<p className="tscs__term-definition">
						The parties hereby agree to submit to the exclusive jurisdiction of the English Courts.
					</p>

					<p className="tscs__term-definition">
						These current terms and conditions of trading dated 22nd January 2016 supersede any previous
						versions.
					</p>

					<p className="tscs__term-definition">
						* Outside normal hours of business minimum hire charge of £100 plus delivery charges will apply.
					</p>

					<h5 className="tscs__section-heading">Credit Cards & Debit Cards</h5>

					<h5 className="tscs__section-heading">General</h5>
					<ul>
						<li className="tscs__term-definition">
							Cards details can only be taken on the phone or by fax.
						</li>
						<li className="tscs__term-definition">
							We can not accept any card details by email, it is not secure transmission medium.
						</li>
						<li className="tscs__term-definition">
							For all transactions we must speak with the cardholder.
						</li>
						<li className="tscs__term-definition">
							Deposits may be secured against cards on pre-authorisation but will not be charged unless
							appropriate and agreed.
						</li>
						<li className="tscs__term-definition">Cards will not be billed until after shipping.</li>
						<li className="tscs__term-definition">
							If you choose to extend your hire, we will bill the same card.
						</li>
						<li className="tscs__term-definition">
							If you lose or damage something, we will bill the same card.
						</li>
						<li className="tscs__term-definition">
							We will not bill anything to any card without providing written advice and talking to you
							about it first.
						</li>
					</ul>

					<h5 className="tscs__section-heading">Credit Cards</h5>
					<p className="tscs__term-definition">
						To sucessfully process a Credit Card correctly, we need the MINIMUM information below:
					</p>

					<ul>
						<li className="tscs__term-definition">Name on card</li>
						<li className="tscs__term-definition">
							Full billing address including postcode as it appears on the card statement
						</li>
						<li className="tscs__term-definition">Card number</li>
						<li className="tscs__term-definition">Start date</li>
						<li className="tscs__term-definition">Expiration date</li>
						<li className="tscs__term-definition">CVV Number</li>
					</ul>

					<p className="tscs__term-definition">
						We may ask for more info depending on the response from our system.
					</p>

					<h5 className="tscs__section-heading">Debit Cards</h5>
					<p className="tscs__term-definition">
						To successfully process a Debit Card correctly, we need the MINIMUM information below:
					</p>

					<ul>
						<li className="tscs__term-definition">Name on card</li>
						<li className="tscs__term-definition">
							Full billing address including postcode as it appears on the card statement
						</li>
						<li className="tscs__term-definition">Card number</li>
						<li className="tscs__term-definition">Switch/Solo start date (if applicable)</li>
						<li className="tscs__term-definition">Switch/Solo expiration date</li>
						<li className="tscs__term-definition">Switch/Solo issue number</li>
						<li className="tscs__term-definition">CVV Number</li>
					</ul>

					<p className="tscs__term-definition">
						We may ask for more info depending on the response from our system.
					</p>
				</div>
			</div>

			<Footer />
		</div>
	);
}

export default TSCS;
