import React from "react";
import {navigate, A} from "hookrouter";
 
 
 
function Nav() {

    const navHome = () => {
        navigate("/");
    };

    const navProjects = () => {
        navigate("/projects");
    };

    const navRetro = () => {
        navigate("/retro");
    };

    const navContact = () => {
        navigate("/contact");
    };

    const navTSCS = () => {
        navigate("/tscs");
    };


    return (
        <div className="navigation">
            <input type="checkbox" className="navigation__checkbox" id="navi-toggle"></input>
            <label htmlFor="navi-toggle" className="navigation__button">
                <span className="navigation__icon">&nbsp;</span>
            </label>

            <div className="navigation__dim-screen">&nbsp;</div>
            
            <div className="navigation__background">&nbsp;</div>

            <nav className="navigation__nav">
                <ul className="navigation__list">
                    <li className="navigation__item">
                        <form onSubmit={navHome}>
                            <button className="navigation__link navigation__link-left1" type ="submit">Home</button>
                        </form>
                    </li>
                    <li className="navigation__item">
                        <form onSubmit={navProjects}>
                            <button className="navigation__link navigation__link-left1" type ="submit">Projects</button>
                        </form>
                    </li>
                    <li className="navigation__item">
                        <form onSubmit={navRetro}>
                            <button className="navigation__link navigation__link-right1" type ="submit">Retro</button>
                        </form>
                    </li>
                    <li className="navigation__item">
                        <form onSubmit={navTSCS}>
                            <button className="navigation__link navigation__link-left2" type ="submit">Ts & Cs</button>
                        </form>
                    </li>
                    <li className="navigation__item">
                        <form onSubmit={navContact}>
                            <button className="navigation__link navigation__link-left2" type ="submit">Contact</button>
                        </form>
                    </li>
                </ul>
            </nav>
        </div>
        
    );
};

export default Nav;